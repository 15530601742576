import React, { FunctionComponent, useState, useContext } from "react";
import { ImageInterface, PortableText, MultiLineHeadline, SanityColorList, Slug } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContext } from "../../services/LocalizedContextService";
// @ts-ignore
import { Link } from "../Link";
import { ArrowRight } from "../../images/icons/arrow-right-two";
import Container from "react-bootstrap/Container";
import FadeIn from "../FadeIn";
import ModalBlock, { ModalBlockInterface } from "../Modal";
import "./styles.scss";

export interface PromoBlockInterface {
  promoBlockType: {
    name: string;
  };
  bgColor?: SanityColorList;
  headline: MultiLineHeadline;
  _rawImage?: ImageInterface;
  _rawIntroduction?: PortableText;
  introduction?: PortableText;
  ctaLabel?: string;
  ctaReference?: {
    _type?: string;
    _id?: string;
    __typename?: string;
    slug?: {
      current: string;
    };
    headline?: MultiLineHeadline;
    _rawIntroduction?: PortableText;
    surveyBlock?: {
      submitCTA: string;
      questions: {
        question: string;
        questionType: string;
      }[];
    };
    parentSection?: {
      slug: {
        current: string;
      };
      parentSection?: Slug;
    };
  };
  fontColor?: {
    value: string;
    title: string;
  };
}

function setGradientCSSClass(colorObj: { value: string, title: string }) {
  const title = colorObj?.title.toLowerCase();
  let color = title || "orange-yellow";
  return `rx-gradient--${color}`;
}

const PromoBlockV2: FunctionComponent<PromoBlockInterface> = ({
  promoBlockType,
  bgColor,
  headline,
  _rawImage,
  _rawIntroduction,
  introduction,
  ctaLabel,
  ctaReference,
  fontColor
}) => {
  const [showModal, setShowModal] = useState(false);

  const { language } = useContext(LocalizedContext);
  const { sanityId, sanityDataset, locale } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const parentPath = ctaReference?.parentSection?.parentSection
    ? `${ctaReference?.parentSection?.parentSection?.slug.current}/${ctaReference?.parentSection?.slug.current}`
    : ctaReference?.parentSection?.slug.current;

  const renderContent = (sectionClasses: string, dataCy: string, fontColor: any) => {
    return (
      <section
        className={sectionClasses}
        data-testid={dataCy}
        style={{
          background: "linear-gradient(98.25deg, #000407 -6.51%, #003B45 16.48%, #00B2A9 58.53%, #72DCD4 103.84%)"
        }}
      >
        <Container fluid>
          <div className="row no-gutters">
            <div className="col-xs-12 col-md-6 promo-block--content">
              <h2
                id={`header1-${ctaLabel}`}
                aria-label={
                  headline.primaryText + " " + (headline.secondaryText || "") + " " + (headline.tertiaryText || "")
                }
              >
                <span className={`header-1-primary ${setGradientCSSClass(fontColor)}`}>{headline.primaryText}</span>
                {headline.secondaryText && <span className="header-1-secondary">{headline.secondaryText}</span>}
                {headline.tertiaryText && <span className="header-1-tertiary">{headline.tertiaryText}</span>}
              </h2>
            </div>
            <div className="col-xs-12 col-md-6">
              <picture
                className="placeholder"
                style={
                  _rawImage?.asset?.metadata
                    ? {
                        paddingTop: `56.25%`
                      }
                    : undefined
                }
              >
                <source
                  media={"(min-width: 1200px)"}
                  srcSet={
                    urlBuilder
                      .image(_rawImage)
                      .auto("format")
                      .quality(80)
                      .width(682)
                      .height(555)
                      .format("webp")
                      .url() || undefined
                  }
                />
                <img
                  src={
                    urlBuilder
                      .image(_rawImage)
                      .auto("format")
                      .quality(80)
                      .width(500)
                      .height(407)
                      .format("webp")
                      .url() || undefined
                  }
                  alt={_rawImage?.alt}
                  loading={"lazy"}
                  className="landscape-cover-image"
                />
              </picture>
            </div>
          </div>
        </Container>
      </section>
    );
  };

  return promoBlockType.name === "Promotion (With Image)" ? (
    <>
      {renderContent(
        `promo-block-v2-tick`,
        "promo-block-v2-tick",
        fontColor
      )}
    </>
  ) : (
    <>{renderContent("promo-block", "promo-block", fontColor)}</>
  );
};

export default PromoBlockV2;
